import { application } from 'services/core'

function CategoryOrders () {
  const elements = {}
  elements.modalSelectorInitialized = false

  const initializeSelectors = () => {
    elements.addCategories = document.querySelector('#add_categories')
    elements.submitButton = document.querySelector('#order_submit_btn')
    elements.associatedCategories = document.querySelector('#categories_ids')
    elements.categoryOrderName = document.querySelector('#category_order_name')
    elements.selectedServiceType = document.querySelector('#category_order_service_type')
    elements.categoryOrderID = document.querySelector('#category_order_id')
    elements.indexCategoryOrderTable = document.querySelector('#category_order_listing')
    elements.pageTitle = document.querySelector('#order_settings_page_title')
    elements.displayTabs = document.querySelectorAll('.order_setting_component')
    elements.orderSettingLogTable = document.querySelector('#order_setting_logs_table')
    elements.startShift = $('.start')
    elements.endShift = $('.end')
    elements.shiftTimeField = $('.time')
  }

  const initializeModalSelectors = () => {
    if (elements.modalSelectorInitialized) return

    elements.modalSelectorInitialized = true
    elements.clearSearchBtn = document.querySelector('#clear_search_btn')
    elements.shopName = document.querySelector('#category_search_query')
    elements.clearFormSubmit = document.querySelector('form#search-categories-form')
    elements.defaultShiftSelector = document.querySelector('#category_default_shift')
    elements.sortableCategories = document.querySelector('.sortable-list')
  }

  const initializeModalListeners = () => {
    $(elements.clearSearchBtn).on('click', clearSearchField)
  }

  const initializeLogsTable = () => {
    $(elements.orderSettingLogTable).DataTable({
      'paging': true,
      'lengthMenu': [[10, 25, 50, 200], [10, 25, 50, 200]],
      'lengthChange': true,
      'searching': false,
      'ordering': true,
      'order': [],
      'info': true,
      'autoWidth': false
    })
  }

  const changeActiveTab = (event) => {
    localStorage.setItem('activeTab', event.target.closest('a').getAttribute('href'))
    const componentString = event.target.textContent
    let title = ''
    switch (componentString) {
      case 'Category Orders':
        title = 'Category Orders'
        break
      case 'Category Order Logs':
        title = 'Category Order Logs'
        break
    }
    elements.pageTitle.innerHTML = title
  }

  const preSelectActiveTab = () => {
    const activeTabLink = localStorage.getItem('activeTab')
    const activeTab = $(`#order_setting_ui a[href="${activeTabLink}"]`)

    if (activeTabLink && activeTab[0]) {
      activeTab.tab('show')
      elements.pageTitle.innerHTML = activeTab[0].text
    }
  }

  const openAssociateCategoriesModal = (event) => {
    if (elements.selectedServiceType.value === '') {
      event.preventDefault()
      alert('Please select service type first')
    } else {
      $(this).attr('disabled', true)
      $(this).html('Please wait...')

      $.ajax({
        type: 'POST',
        dataType: 'script',
        url: window.category_order_associate_categories,
        data: {
          existing_category_ids: elements.associatedCategories.value,
          service_type_id: elements.selectedServiceType.value,
          category_order_id: elements.categoryOrderID.value
        },
        success: () => {
          initializeModalSelectors()
          initializeModalListeners()
          initializeSortableCategoryTable()
        }
      })
    }
  }

  const validateFormFields = (event) => {
    if (elements.categoryOrderName.value.trim() === '') {
      event.preventDefault()
      alert('Name cannot be empty')
    } else if (elements.associatedCategories.value.length <= 0) {
      event.preventDefault()
      alert('Please select category ranks')
    }
  }

  const clearSearchField = () => {
    elements.shopName.value = ''
  }

  const fetchFilteredCategories = (event) => {
    $.ajax({
      url: window.category_order_search_categories,
      type: 'GET',
      data: {
        selected_shift: event.target.value,
        service_type_id: elements.selectedServiceType.value,
        category_order_id: elements.categoryOrderID.value
      }
    })
  }

  const initializeSortableCategoryTable = () => {
    $('.sortable-list').sortable({
      connectWith: '.sortable-list',
      placeholder: 'sortable-placeholder',
      update: (event, ui) => {
        const childrens = event.target.querySelectorAll('.sortable-item')
        let i = 1
        const sortedItems = [...childrens].map((el) => {
          const lastColumn = el.querySelector('td:last-child')
          lastColumn.innerHTML = i++
          return Number(el.dataset.recordId)
        })
        elements.associatedCategories.value = JSON.stringify(sortedItems)
      }
    }).disableSelection()
  }

  const handleSelectedCategories = () => {
    elements.associatedCategories.value = ''

    if (elements.selectedServiceType.value === '') {
      elements.addCategories.setAttribute('disabled', 'true')
    } else {
      elements.addCategories.removeAttribute('disabled')
    }
  }

  const shiftDateTimePicker = () => {
    elements.shiftTimeField.datetimepicker({
      format: 'HH:mm',
      useCurrent: false
    })
    $('startShift').on('dp.change', function (e) {
      elements.endShift.data('DateTimePicker').minDate(e.date)
    })

    $('.end').on('dp.change', function (e) {
      elements.startShift.data('DateTimePicker').maxDate(e.date)
    })
  }

  const initializeListeners = () => {
    $(elements.addCategories).on('click', openAssociateCategoriesModal)
    $(elements.submitButton).on('click', validateFormFields)
    $(document).on('change', '#category_default_shift', fetchFilteredCategories)
    $(elements.selectedServiceType).on('change', handleSelectedCategories)
    $(elements.displayTabs).on('click', changeActiveTab)
  }

  return () => {
    initializeSelectors()
    initializeListeners()
    preSelectActiveTab()
    initializeLogsTable()
    shiftDateTimePicker()
  }
}

application.register(CategoryOrders, import.meta)
export default CategoryOrders
